/* ===================== Adwaita Theme Gradients ====================== */

:root:not([data-theme="dark"]) .adwaita-toolbar {
	background-image: linear-gradient(#ededed, #dad6d2);
	border-bottom: 1px solid #bfb8b1;
	color: var(--primary-color);
}

:root[data-theme="dark"] .adwaita-toolbar {
	background-image: linear-gradient(#2b2b2b, #262626);
	border-bottom: 1px solid #070707;
}

@media (prefers-color-scheme: dark) {
	:root:not([data-theme="light"]) .adwaita-toolbar {
		background-image: linear-gradient(#2b2b2b, #262626);
		border-bottom: 1px solid #070707;
	}
}
