main {
	--link-color: #049ccf;
	--link-decoration: underline;
}

#image-picker {
	padding: 0;
}

#image-picker .accordion > *:not(summary) {
	padding: 0;
}

#pexels-gallery {
	display: grid;
	justify-content: space-evenly;
	grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	gap: 0.3em;
}

@media (min-width: 500px) {
	#pexels-gallery {
		grid-auto-flow: dense;
	}
}

#pexels-gallery > img {
	width: unset;
	height: auto;
}

#pexels-gallery [data-image-src] {
	position: relative;
	transition: background-color 400ms ease-in-out, transform 400ms ease-in-out;
	object-fit: cover;
	object-position: center center;
	padding: 6px;
}

@media (any-hover: hover) {
	#pexels-gallery [data-image-src]:hover {
		z-index: 1;
		transform: scale(1.5) rotate(5deg);
	}
}

#pexels-gallery [data-image-src].selected-img {
	background-color: var(--accent-color);
}

.form-group .input:not(:invalid) + .status-box {
	display: none;
}

.form-group .input:required:invalid:placeholder-shown + .status-box,
.form-group .input:focus + .status-box, .form-group .input:disabled + .status-box {
	display: none;
}

.form-group .input-label.disabled {
	opacity: 0.6;
}

article p:first-letter {
	margin-left: 2em;
}

#layout-options {
	justify-content: space-around;
}

.layout-option .btn-toggle {
	display: inline-block;
	text-align: center;
	color: var(--alt-color);
	padding: 8px;
	border-radius: 6px;
}

details.accordion > summary {
	border-radius: 6px;
}

.dragging {
	border: 3px solid red;
}
