#nav {
	padding: 8px;
	font-size: 18px;
	font-size: clamp(18px, 1.2em, 21px);
	gap: 8px;
	justify-content: flex-start;
	min-height: 38px;
}

#nav .btn {
	max-width: 220px;
}

#install-btn, #help-btn, #menu-btn {
	margin-left: auto;
}

#menu-btn.btn {
	max-width: 47px;
}

#nav .btn.btn-default:disabled * {
	opacity: 0.6;
}

#app-menu {
	min-width: 50px;
}
