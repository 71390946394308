.form-group {
	border: 1px solid transparent;
	padding: var(--form-group-padding, 0.4em 0.8em);
	margin-bottom: 0.4em;
	border-radius: 6px;
	transition: border-color 200ms ease-in-out;

	&:focus-within {
		border-color: var(--form-group-border, #cacaca);
	}

	& .input-label {
		display: block;
		margin: 0.4em 0;
	}

	& .input-label.required::after {
		content: ' *';
		display: inline;
		font-size: 0.8em;
		font-weight: 800;
	}

	& .input, & .input:required:invalid:placeholder-shown {
		display: block;
		width: var(--input-width, 100%);
		max-width: 100%;
		color: currentColor;
		border-style: solid;
		border-color: var(--input-border, #cacaca);
		border-width: 0 0 1px 0;
		background: transparent;
		padding: var(--form-group-padding, 0.5em 0.3em);
		transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
	}

	& textarea.input {
		resize: vertical;
	}

	& .input:invalid {
		box-shadow: none;
	}

	& .input:invalid:not(:focus) {
		color: var(--invalid-color, #-871717);
		border-color: currentColor;
		background-color: var(--invalid-background, #F7C1C1);
	}

	& input[hidden] + label[for].btn-toggle {
		background-color: var(--button-disabled-background);
		margin: var(--form-group-margin, 0.3rem);
		transition: background-color 300ms ease-in-out;
	}

	& input[hidden]:checked + label[for].btn-toggle {
		background-color: var(--button-background);
	}

	& input[hidden]:not(:checked) + label[for].btn-toggle .when-checked {
		display: none;
	}

	& input[hidden]:checked + label[for].btn-toggle .when-unchecked {
		display: none;
	}
}
