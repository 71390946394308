@charset "UTF-8";
@import url("./vars.css");
@import url("https://cdn.kernvalley.us/css/normalize/normalize.css");
@import url("https://cdn.kernvalley.us/css/core-css/rem.css");
@import url("https://cdn.kernvalley.us/css/core-css/viewport.css");
@import url("https://cdn.kernvalley.us/css/core-css/element.css");
@import url("https://cdn.kernvalley.us/css/core-css/class-rules.css");
@import url("https://cdn.kernvalley.us/css/core-css/cdn-fonts.css");
@import url("https://cdn.kernvalley.us/css/animate.css/animate.css");
@import url("https://cdn.kernvalley.us/css/core-css/animations.css");
@import url("https://cdn.kernvalley.us/css/core-css/scrollbar.css");
@import url("https://cdn.kernvalley.us/css/core-css/forms.css");
@import url("https://cdn.kernvalley.us/css/core-css/accordion.css");
@import url("./layout.css");

.input:disabled {
	opacity: 0.7;
}

.form-group {
	border: none;
}

#wfd-dialog {
	max-width: 80vw;
	border-radius: 12px;
}

.btns.flex {
	justify-content: space-around;
}

.btns.flex .btn.grow-1 {
	max-width: 240px;
}
