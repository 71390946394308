@import url("https://cdn.kernvalley.us/css/core-css/theme/adwaita/index.css");
@import url("https://cdn.kernvalley.us/css/core-css/theme/properties.css");

@media all {
	:root[data-theme], :root:not([data-theme]) {
		--accent-color-light: #228282;
		--accent-color-dark: var(--accent-color-light);
		--button-primary-background-light: var(--accent-color-light);
		--button-primary-background-dark: var(--button-primary-background-light);
		--button-primary-hover-background-light: var(--button-primary-background-light);
		--button-primary-hover-background-dark: var(--button-primary-hover-background-light);
		--button-primary-active-background-light: #006060;
		--button-primary-active-background-dark: var(--button-primary-active-background-light);
		--sidebar-max-width: 30vw;
		--scrollbar-width: auto;
		--header-height: 0;
		--button-background: var(--button-primary-background);
	}
}
